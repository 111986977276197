body {
  background-color: #000000;
  color: #ffffff;
}

.app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 5vh
}

.header {
  font-family: "Georgia", serif; 
  font-size: calc(24px + 5vh);
}

.content {
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5vh;
  font-size: 16px;
}

.pw-header {
  margin-right: 5px;
}

.pw-line {
  background-color: #000;
}

.pw-input {
  background-color: #000;
  margin-left: 5px;
  font-family: 'Courier New', Courier, monospace;
}

*:focus {
  outline: none;
}

input[name="pw-input"] {
  background-color: #000;
  border: 0px;
  color: #FFF;
}

.enter-btn {
  margin-left: 5px;
  cursor: pointer;
}

.blinking {
  animation:blinkingText 1s infinite;
  font-size: 24px;
}
@keyframes blinkingText{
  0%{     color: #FFF;    }
  49%{    color: transparent; }
  50%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #FFF;    }
}